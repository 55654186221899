import { Auth } from "aws-amplify";
import axios from "axios";
import { get } from "lodash";

const fetchToken = async () => {
    const res = await Auth.currentSession();
    return get(res, "idToken.jwtToken");
};

const client = async (headers = {}) => {
    const token = await fetchToken();
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
            ...headers,
        },
    });
};

const api = {
    async get(url, params, headers) {
        try {
            const response = await (await client(headers)).get(url, { params });
            return handleResponse(response);
        } catch (err) {
            return handleError(err);
        }
    },

    async post(url, data, headers) {
        try {
            const response = await (await client(headers)).post(url, data);
            return handleResponse(response);
        } catch (err) {
            return handleError(err);
        }
    },

    async put(url, data, headers) {
        try {
            const response = await (await client(headers)).put(url, data);
            return handleResponse(response);
        } catch (err) {
            console.error(`🙂 -> err:`, err);
            return handleError(err);
        }
    },

    async delete(url, data, headers) {
        try {
            const response = await (await client(headers)).delete(url, { data });
            return handleResponse(response);
        } catch (err) {
            return handleError(err);
        }
    },
};

function handleResponse(response) {
    if (response.status >= 200 && response.status < 300) {
        return { status: get(response, "status"), data: get(response, "data") };
    }
    throw new Error(`Request failed with status code ${get(response, "status")}`);
}

function handleError(err) {
    const status = get(err, "response.status", 500);
    const message = get(err, "response.data.message", get(err, "message"));
    return {
        status,
        message,
    };
}

export default api;
