import React,{useState,useEffect} from 'react';
import './App.css';
import Router from './Router';
import AppContext from './library/Context';
import Amplify, { Auth } from "aws-amplify";

function App() {
  const [isAuthenticated, setUserHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
    userDetails()
  }, []);

  async function onLoad() {
    try {
      Amplify.configure({
        Auth: {
          mandatorySignIn: true,
          region: `${process.env.REACT_APP_REGION}`,
          userPoolId: `${process.env.REACT_APP_USER_POOL_ID}`,
          userPoolWebClientId: `${process.env.REACT_APP_CLIENT_ID}`,
        },
      });
      await Auth.currentSession();
      setUserHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        console.error(e);
      }
    }
    }

  async function userDetails(){
    try{
      await Auth.currentAuthenticatedUser()
      setUserHasAuthenticated(true)
    }
    catch(e){
      console.error(e)
    }
  }
  return (
      <AppContext.Provider value={{ isAuthenticated, setUserHasAuthenticated }}>
          <Router />
      </AppContext.Provider>
  );
}

export default App;
