import { useContext, createContext } from 'react'
import React from 'react'
export const AppContext = createContext(null)

export const useAppContext=(props)=> {
  return useContext(AppContext)

}

export default AppContext
