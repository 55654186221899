import React from 'react'
import loading from '../../assets/images/Dual Ring-1s-200px.svg'

import './loading.scss'

export const Loading = () => {
  return (
    <div className="loadingScreen">
      <div className="loading">
        <img src={loading} alt="loading"></img>
      </div>
    </div>
  )
}
export default Loading
