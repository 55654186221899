import PropTypes from "prop-types";
import React from "react";
import { Button } from "../../stories/Button/Button";
import "./apiKeyView.scss";

export function ApiKeyView({ showModal, title, apiKey, isCopied, onClose, onCopy }) {
    if (!showModal) {
        return null;
    }

    return (
        <div className="apiViewModal">
            <div className="apiModal_content">
                <div className="apiModal_title">
                    <h4>{title}</h4>
                </div>
                <div className="apiModal_key">{apiKey}</div>
                <div className="apiModal_buttons">
                    <div className="noGenerate">
                        <Button onClick={onCopy} label={isCopied ? "Copied" : "Copy"} primary={true} size={"large"}></Button>
                    </div>
                    <div className="yesGenerate">
                        <Button className="yesG" label={"Close"} size={"large"} onClick={onClose}></Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ApiKeyView;

ApiKeyView.propTypes = {
    showModal: PropTypes.bool.isRequired,
    isCopied: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onCopy: PropTypes.func.isRequired,
};
