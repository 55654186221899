import React from "react";
import { FormControl, InputBase, InputLabel, MenuItem, Select } from "@mui/material";
import "./dropdown.scss";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const BootstrapInput = styled(InputBase)(() => ({
    "& .MuiInputBase-input": {
        borderRadius: 7,
        position: "relative",
        border: "1px solid #ced4da",
        padding: "0px !important",
        paddingLeft: "10px !important",
        height: "50px !important",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        color: "#8D91A1",
        font: "normal normal 600 14px/21px Poppins",
        "&:focus": {
            borderColor: "#ced4da",
        },
    },
}));

export default function SimpleDropdown({ title, value, onChange = null, data }) {
    const handleChange = (event) => {
        onChange && onChange(event); //NOSONAR
    };

    return (
        <FormControl id="simple-dropdown">
            <InputLabel id="demo-simple-select-label" sx={{ background: "white", color: "#8D91A1", padding: "0 5px 0 5px", font: "normal normal 600 14px/21px Poppins", "&.Mui-focused": { color: "#8D91A1" } }}>
                {title}
            </InputLabel>
            <Select labelId="demo-simple-select-label" label={title} id="demo-simple-select" value={value} onChange={handleChange} input={<BootstrapInput />}>
                <MenuItem value="" sx={{ font: "normal normal 600 14px/21px Poppins", color: "#8D91A1" }}>
                    <em>--Select--</em>
                </MenuItem>
                {data?.map(({ value, label }) => (
                    <MenuItem value={value} name={label} key={value} sx={{ font: "normal normal 600 14px/21px Poppins", color: "#8D91A1" }}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

SimpleDropdown.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    data: PropTypes.array,
};
