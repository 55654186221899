import React from 'react'
// import { Button } from '../../stories/Button/Button'
import './edit.scss'
export const Edit = (props) => {
  if (!props.showEditModel) {
    return null
  }
  return (
    <div className="editModal" onClick={props.onClose}>
      <div
        className="edit_content"
        onClick={(e) => e.stopPropagation()}
        data-testid="stop"
      >
        <div className="edit_header">
          <h3>{props.title}</h3>
          <div className="edit_body">{props.children}</div>
        </div>
      </div>
    </div>
  )
}
export default Edit
