import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react";
import _, { get } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import api from "../../api/axiosClient";
import searchIcon from "../../assets/icons/Icon feather-search.svg";
import ApiGenerate from "../../components/apiGenerateModel/ApiGenerate";
import ApiKeyView from "../../components/apiViewModel/ApiKeyView";
import AssociateAccountModal from "../../components/associateAccountModal/AssociateAccountModal";
import { Header } from "../../components/header/Header";
import Loading from "../../components/loader/Loading";
import PromoteModal from "../../components/promoteModal/PromoteModal";
import Sidebar from "../../components/sidebar/Sidebar";
import { Button } from "../../stories/Button/Button";
import "./ag-grid-overrides.scss";
import "./customers.scss";

const promoteDataInitialValue = {
    customerName: "",
    apiKeyPreference: "",
};

const associateDataInitialValue = {
    customerName: "",
    accountName: "",
};

const size = 10;

export const Vendors = () => {
    const [tableData, setTableData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState();
    const [pageSummary, setPageSummary] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [selectedRow, setSelectedRow] = useState();
    const [showPromoteModal, setShowPromoteModal] = useState(false);
    const [promoteData, setPromoteData] = useState(promoteDataInitialValue);
    const [promoteDataError, setPromoteDataError] = useState({});
    const [showAssociateModal, setShowAssociateModal] = useState(false);
    const [associateData, setAssociateData] = useState(associateDataInitialValue);
    const [associateAccountError, setAssociateAccountError] = useState({});

    const [apiKey, setApiKey] = useState({ view: false, copied: false, generate: false });
    const [apiKeyData, setApiKeyData] = useState("");

    const columnDefs = [
        {
            headerName: "Vendor Id",
            field: "vendor_id",
            width: 200,
            suppressMovable: true,
            cellStyle: { paddingTop: "20px", paddingLeft: "38px" },
            checkboxSelection: true,
        },
        {
            headerName: "Vendor Name",
            field: "name",
            width: 200,
            suppressMovable: true,
            cellStyle: { paddingTop: "20px", paddingLeft: "38px" },
        },
        {
            headerName: "View Key",
            width: 220,
            suppressMovable: true,
            cellStyle: { paddingTop: "20px", paddingLeft: "30px" },
            cellRendererFramework: (params) => ( //NOSONAr
                <div className="actionIcons">
                    <button
                        className="viewButton"
                        onClick={async () => {
                            setSelectedRow(params.data);
                            const apiKeyGetResp = await getApiKey(get(params, "data.source_system"), get(params, "data.vendor_id"));
                            if (apiKeyGetResp) setApiKey((state) => ({ ...state, view: true }));
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" id="view" width="14.722" height="14.722" viewBox="0 0 36 27">
                            <g transform="translate(0 -4.5)">
                                <path className="a" fill="none" stroke="#c52131" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" d="M1.5,18S7.5,6,18,6,34.5,18,34.5,18,28.5,30,18,30,1.5,18,1.5,18Z" />
                                <path className="a" fill="none" stroke="#c52131" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" d="M22.5,18A4.5,4.5,0,1,1,18,13.5,4.5,4.5,0,0,1,22.5,18Z" />
                            </g>
                        </svg>
                    </button>
                </div>
            ),
        },
        {
            headerName: "Source System",
            field: "source_system",
            sortable: true,
            sortingOrder: ["asc", "desc"],
            width: 200,
            suppressMovable: true,
            cellStyle: { paddingTop: "20px", paddingLeft: "40px" },
        },
        {
            headerName: "Generate Key",
            field: "CustomerStatus",
            width: 220,
            suppressMovable: true,
            cellStyle: { paddingTop: "20px", paddingLeft: "30px" },
            cellRendererFramework: (params) => ( //NOSONAr
                <div className="actionIcons">
                    <button
                        className="refreshButton"
                        onClick={() => {
                            setApiKey((state) => ({ ...state, generate: true }));
                            setSelectedRow(params.data);
                        }}
                    >
                        <svg id="refresh" xmlns="http://www.w3.org/2000/svg" width="15.404" height="16.89" viewBox="0 0 15.404 16.89">
                            <path className="a" fill="#c52131" d="M-6302.74-4477.226a6.961,6.961,0,0,1-2.261-5.113,6.968,6.968,0,0,1,2.209-5.067,7.925,7.925,0,0,1,5.327-2.166v-1.668a.786.786,0,0,1,.808-.76.85.85,0,0,1,.431.118l.007,0,3.761,2.451a.747.747,0,0,1,.375.642.748.748,0,0,1-.374.643l-3.774,2.489a.837.837,0,0,1-.43.118.787.787,0,0,1-.809-.76v-1.836a6.022,6.022,0,0,0-6,5.793,6,6,0,0,0,6.184,5.792,6.067,6.067,0,0,0,6.16-5.308.749.749,0,0,1,.763-.659.8.8,0,0,1,.569.236.688.688,0,0,1,.194.544,7.034,7.034,0,0,1-2.451,4.707,7.982,7.982,0,0,1-5.235,1.917A7.932,7.932,0,0,1-6302.74-4477.226Z" transform="translate(6305 4492)" />
                        </svg>
                    </button>
                </div>
            ),
        },
    ];

    const gridOptions = {
        rowStyle: {
            font: "normal normal normal 14px/21px Poppins",
            color: "#71758A",
            opacity: 1,
        },
        getRowStyle: (params) => {
            if (params.node.rowIndex % 2 === 0) {
                return {
                    background: "#F5F5F5 0% 0% no-repeat padding-box",
                    opacity: 0.8,
                    borderRadius: "10px",
                    height: "70px",
                };
            } else {
                return {
                    background: "#FAFAFA 0% 0% no-repeat padding-box",
                    opacity: 0.8,
                    borderRadius: "10px",
                    height: "70px",
                };
            }
        },
    };

    const getApiKey = useCallback(async (source_system, vendor_id) => {
        setLoading(true);
        try {
            const { data, message } = await api.get(`/vendor-api-key/get?source_system=${source_system}&vendor_id=${vendor_id}`);
            console.info(`🙂 -> file: Vendors.js:262 -> data, message:`, data, message);
            if (data) {
                if (get(data, "message")) {
                    throw new Error(get(data, "message"));
                }
                setApiKeyData(get(data, "data", ""));
                setLoading(false);
            }
            if (message) {
                setLoading(false);
                throw new Error(message);
            }
        } catch (err) {
            console.error(err);
            setLoading(false);
            toast.error(err.message);
            return false;
        }
        return true;
    }, []);

    const getUsers = useCallback(async (page, keyword) => {
        setLoading(true);
        try {
            const { data, message } = await api.get(`/vendor/get?page=${page}&size=${size}&keyword=${keyword}`);
            console.info(`🙂 -> file: Vendors.js:262 -> data, message:`, data, message);
            if (data) {
                if (page <= 1) {
                    setTableData(null);
                }
                setTableData(get(data, "data", []));
                setPageCount(Math.ceil(get(data, "count", 0) / size));
                setLoading(false);
            }
            if (message) {
                setLoading(false);
                throw new Error(message);
            }
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        getUsers(pageSummary, searchText);
    }, [getUsers, pageSummary]);

    const search = _.debounce((text) => {
        setSearchText(text);
    }, 100);

    const searchFilter = async () => {
        setPageSummary(1);
        getUsers(1, searchText);
    };

    const handlePageClick = async (data) => {
        let currentPage = data.selected + 1;
        setPageSummary(currentPage);
    };

    const gridRef = useRef();

    const onSelectionChanged = useCallback(() => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelectedRow(get(selectedRows, "[0]"));
    }, []);

    const handlePromoteClose = () => {
        setShowPromoteModal(false);
        setPromoteData(promoteDataInitialValue);
        setPromoteDataError({});
    };

    const promoteCustomerHandler = async (e) => {
        e.preventDefault();
        const isValid = promoteValidation();
        if (isValid) {
            setLoading(true);
            try {
                const accountDetail = {
                    vendorName: get(selectedRow, "name"),
                    keyPreference: get(promoteData, "apiKeyPreference"),
                    vendor_id: get(selectedRow, "vendor_id"),
                    source_system: get(selectedRow, "source_system"),
                };
                const { status, data, message } = await api.post("/vendor/promote", accountDetail);
                console.info(`🙂 -> file: Accounts.js:102 -> status, data, message:`, status, data, message);
                if (data) {
                    handlePromoteClose();
                    getUsers(pageSummary, searchText);
                    setLoading(false);
                    toast.success(get(data, "message", "Account created."));
                }
                if (message) {
                    setLoading(false);
                    throw new Error(message);
                }
            } catch (err) {
                console.error(err);
                toast.error(err.message);
            }
        }
    };

    const promoteValidation = () => {
        const apiKeyPreferenceError = {};
        const regex1 = /^[a-zA-Z0-9]*$/;
        let isValid = true;
        if (promoteData.apiKeyPreference.trim().length < 1) {
            apiKeyPreferenceError.apiKeyPreferenceEmpty = "Api key preference is required!";
            isValid = false;
        } else if (!regex1.test(promoteData.apiKeyPreference)) {
            apiKeyPreferenceError.apiKeyPreferenceSpe = "No special characters allowed";
            isValid = false;
        }
        setPromoteDataError(apiKeyPreferenceError);
        return isValid;
    };

    const associateAccountHandler = async (e) => {
        e.preventDefault();
        const isValid = associateAccountValidation();
        if (isValid) {
            setLoading(true);
            try {
                const accountDetail = {
                    vendorName: get(selectedRow, "name"),
                    accountName: get(associateData, "accountName"),
                    vendor_id: get(selectedRow, "vendor_id"),
                    source_system: get(selectedRow, "source_system"),
                };
                const { status, data, message } = await api.post("/vendor/associate", accountDetail);
                console.info(`🙂 -> file: Accounts.js:102 -> status, data, message:`, status, data, message);
                if (data) {
                    handleAssociateAccountClose();
                    getUsers(pageSummary, searchText);
                    setLoading(false);
                    toast.success(get(data, "message", "Vendor associated with account."));
                }
                if (message) {
                    setLoading(false);
                    throw new Error(message);
                }
            } catch (err) {
                console.error(err);
                toast.error(err.message);
            }
        }
    };

    const associateAccountValidation = () => {
        const associateAccountDataError = {};
        let isValid = true;
        if (associateData.accountName.trim().length < 1) {
            associateAccountDataError.apiKeyPreferenceEmpty = "Account is required!";
            isValid = false;
        }
        setAssociateAccountError(associateAccountDataError);
        return isValid;
    };

    const handleAssociateAccountClose = () => {
        setShowAssociateModal(false);
        setAssociateData(promoteDataInitialValue);
        setAssociateAccountError({});
    };

    const generateApiKeyHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const customerDetail = {
                name: get(selectedRow, "name"),
                vendor_id: get(selectedRow, "vendor_id"),
                source_system: get(selectedRow, "source_system"),
            };
            const { status, data, message } = await api.post("/vendor-api-key/create", customerDetail);
            console.info(`🙂 -> file: Accounts.js:102 -> status, data, message:`, status, data, message);
            if (data) {
                handleAssociateAccountClose();
                getUsers(pageSummary, searchText);
                setLoading(false);
                toast.success(get(data, "message", "Api key created."));
                setApiKey((state) => ({ ...state, generate: false }));
            }
            if (message) {
                setLoading(false);
                throw new Error(message);
            }
        } catch (err) {
            console.error(err);
            toast.error(err.message);
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Vendors</title>
            </Helmet>
            <Header />
            <Sidebar />
            <div className="customers_content">
                <div className="customers_card_content">
                    <div className="cardCustomers">
                        <div className="customers_card_body">
                            <div className="customers_label_text">
                                <h3>Vendors</h3>
                            </div>

                            <div className="searchFilter">
                                <img src={searchIcon} alt="search" className="sIcon" />
                                <input type="text" className="search_bar" placeholder="Find Vendor" onChange={(e) => search(e.target.value)} onKeyDown={(e) => get(e, "key") === "Enter" && searchFilter()} />
                                <input type="submit" onClick={searchFilter} />
                            </div>

                            <div className="addCustomer">
                                <Button
                                    label={"Promote"}
                                    onClick={() => {
                                        setPromoteData({ customerName: get(selectedRow, "name"), apiKeyPreference: "" });
                                        setShowPromoteModal(true);
                                    }}
                                    disabled={!selectedRow}
                                />
                                <Button
                                    label={"Associate Account"}
                                    className="associate-account-btn"
                                    disabled={!selectedRow}
                                    onClick={() => {
                                        setShowAssociateModal(true);
                                        setAssociateData({ customerName: get(selectedRow, "name"), accountName: "" });
                                    }}
                                />
                            </div>

                            <div
                                className="ag-theme-balham"
                                style={{
                                    width: "90%",
                                    height: 950,
                                    marginLeft: 12,
                                }}
                            >
                                <AgGridReact ref={gridRef} columnDefs={columnDefs} rowData={tableData} gridOptions={gridOptions} pagination={true} paginationPageSize={100} paginationAutoPageSize={true} suppressScrollOnNewData={true} suppressHorizontalScroll={true} rowSelection="single" checkboxSelection={true} onSelectionChanged={onSelectionChanged} headerHeight="50" suppressPaginationPanel={true} suppressDragLeaveHidesColumns={true} rowHeight="80" overlayLoadingTemplate={`<span className="ag-overlay-loading-center"/>`}></AgGridReact>
                                {tableData && (
                                    <div className="mainPa">
                                        <ReactPaginate previousLabel={"<"} nextLabel={">"} onPageChange={handlePageClick} initialPage={0} marginPagesDisplayed={1} pageRangeDisplayed={1} containerClassName={"pagination justify-content-end"} disabledClassName={"disabledPagination"} pageCount={pageCount} pageClassName={"page-item"} pageLinkClassName={"page-link"} previousClassName={"page-item"} previousLinkClassName={"page-link"} nextClassName={"page-item"} nextLinkClassName={"page-link"} activeClassName={"active"} />
                                    </div>
                                )}

                                <div className="summaryPanel">
                                    <span className="summary">
                                        {pageSummary} of {pageCount}
                                    </span>
                                </div>
                            </div>
                            <ApiGenerate showModal={apiKey.generate} onClose={() => setApiKey((state) => ({ ...state, generate: false }))} title="Are you sure you want to update the API for the vendor?" onGenerate={generateApiKeyHandler} />
                            <ApiKeyView
                                showModal={apiKey.view}
                                apiKey={apiKeyData}
                                isCopied={apiKey.copied}
                                onClose={() => setApiKey((state) => ({ ...state, view: false, copied: false }))}
                                title="Api Key"
                                onCopy={async () => {
                                    try {
                                        if (apiKey.copied) {
                                            return;
                                        } else {
                                            await window.navigator.clipboard.writeText(apiKeyData);
                                            setApiKey((state) => ({ ...state, copied: true }));
                                        }
                                    } catch (e) {
                                        console.error(e);
                                        toast.error("Failed to copy API key")
                                    }
                                }}
                            />
                            <PromoteModal
                                showModel={showPromoteModal}
                                formData={promoteData}
                                setFormData={setPromoteData}
                                apiKeyPreferenceError={promoteDataError}
                                onSubmit={promoteCustomerHandler}
                                handleClose={handlePromoteClose}
                                title="Promote Vendor to VendorAccount"
                                dropdownData={[
                                    { value: "byVendor", label: "By Vendor" },
                                    { value: "byVendorAccount", label: "By VendorAccount" },
                                ]}
                            />
                            <AssociateAccountModal showModel={showAssociateModal} formData={associateData} setFormData={setAssociateData} associateAccountError={associateAccountError} onSubmit={associateAccountHandler} handleClose={handleAssociateAccountClose} title={"Promote Vendor to VendorAccount"} type={"vendor"}/>
                            {loading && <Loading />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Vendors;
