import SimpleDropdown from "../dropdown/Dropdown";
import { Modal } from "../modal/Modal";
import PropTypes from "prop-types";

const AddAccountModal = ({ type = "ADD", showModel, setShowModel, onSubmit, formData, setFormData, nameErrors, apiKeyPreferenceError, handleClose, title, dropdownData }) => (
    <Modal title={type === "EDIT" ? "Edit Account" : "Add New Account"} onClose={() => setShowModel(false)} showModel={showModel} className={"modal_content account_modal"}>
        <form onSubmit={onSubmit}>
            <div className="form_group">
                <input
                    type="text"
                    className="form_control"
                    id="name"
                    name="Name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={(e) =>
                        setFormData((prev) => ({
                            ...prev,
                            name: e.target.value,
                        }))
                    }
                />
                {Object.keys(nameErrors).map((key, index) => {
                    return (
                        <div key={key} className="errorMessage">
                            {nameErrors[key]}{" "}
                        </div>
                    );
                })}
            </div>
            <div className="form_group">
                <SimpleDropdown
                    title="API Key Preference"
                    displayEmpty
                    value={formData.apiKeyPreference}
                    onChange={(e) =>
                        setFormData((prev) => ({
                            ...prev,
                            apiKeyPreference: e.target.value,
                        }))
                    }
                    data={dropdownData}
                />
                {Object.keys(apiKeyPreferenceError).map((key, index) => {
                    return (
                        <div key={key} className="errorMessage">
                            {apiKeyPreferenceError[key]}{" "}
                        </div>
                    );
                })}
            </div>
            <div className="footerButtons">
                <div className="save">
                    <button type="submit">Save</button>
                </div>

                <div className="cancelButton">
                    <button onClick={handleClose}>Close</button>
                </div>
            </div>
        </form>
    </Modal>
);

AddAccountModal.propTypes = {
    type: PropTypes.string,
    showModel: PropTypes.bool,
    setShowModel: PropTypes.func,
    onSubmit: PropTypes.func,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    nameErrors: PropTypes.object,
    apiKeyPreferenceError: PropTypes.object,
    handleClose: PropTypes.func,
    title: PropTypes.string,
    dropdownData: PropTypes.arrayOf(PropTypes.object),
};

export default AddAccountModal;