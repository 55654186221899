import React from 'react'
import './toggle.scss'

export const Toggle = ({ isToggled, onToggle }) => {
  return (
    <label className="switch">
      <input type="checkbox" checked={isToggled} onChange={onToggle} />
      <span className="slider"></span>
    </label>
  )
}
