import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useAppContext } from './library/Context'
import Accounts from './pages/accounts/Accounts'
// import ApiManagement from './pages/apiManagement/ApiManagement'
import Customers from './pages/customers/Customers'
import Login from './pages/login/Login'
// import UserActivity from './pages/userActivity/UserActivity'
import VendorAccounts from './pages/vendor-account/VendorAccounts'
import Vendors from './pages/vendors/Vendors'
import Webhook from './pages/webhook/Webhook'

function Router() {
  const {isAuthenticated} = useAppContext();

  return (

    <Switch>
      <Route path="/login">
        {!isAuthenticated ? (
          <Login/>
        )
      : (
        <Redirect to = {{pathname:"/customers"}}/>
      )}
      </Route>

      <Route exact path ="/">
        <Redirect to ={{
          pathname : "/customers",
        }} />
        </Route>

      <PrivateRoute path="/customer-accounts" isAuthenticated={isAuthenticated}>
        <Accounts />
      </PrivateRoute>

      <PrivateRoute path="/vendor-accounts" isAuthenticated={isAuthenticated}>
        <VendorAccounts />
      </PrivateRoute>

      <PrivateRoute path="/customers" isAuthenticated={isAuthenticated}>
        <Customers />
      </PrivateRoute>

      <PrivateRoute path="/vendors" isAuthenticated={isAuthenticated}>
        <Vendors />
      </PrivateRoute>

      {/* <PrivateRoute path="/apimanagement" isAuthenticated={isAuthenticated}>
        <ApiManagement />
      </PrivateRoute> */}

      <PrivateRoute path="/webhook" isAuthenticated={isAuthenticated} >
        <Webhook />
      </PrivateRoute>

      {/* <PrivateRoute path="/useractivity" isAuthenticated={isAuthenticated}>
        <UserActivity />
      </PrivateRoute> */}

    </Switch>

  )
}
function PrivateRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default Router
