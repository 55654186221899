import React from 'react'
import { Button } from '../../stories/Button/Button'
import './delete.scss'
export const Delete = (props) => {
  if (!props.showDelete) {
    return null
  }

  return (
    <div className="deleteModal">
      <div className="deleteModal_content">
        <div className="deleteModal_title">
          <h3>{props.title} ?</h3>
        </div>

        <div className="deleteModal_buttons">
          <div className="no">
            <Button
              label={'No'}
              primary={true}
              size={'large'}
              onClick={props.onClose}
            ></Button>
          </div>
          <div className="yes">
            <Button
              className="yesButton"
              onClick={props.onDelete}
              label={'Yes'}
              size={'large'}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Delete
