import React from 'react'
import './changePassword.scss'

export function ChangePassword(props) {
  if (!props.showModel) {
    return null
  }
  return (
    <div className="Modal">
      <div className={props.className}>
        <div className="modal_header">
          <h3>{props.title}</h3>
        </div>
        <div className="change_body">{props.children}</div>
      </div>
    </div>
  )
}
