import { AgGridReact } from "ag-grid-react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import api from "../../api/axiosClient";
import Loading from "../loader/Loading";
import { Modal } from "../modal/Modal";
import "./customerByAcc.scss";

const gridOptions = {
    rowStyle: {
        font: "normal normal normal 14px/21px Poppins",
        color: "#71758A",
        opacity: 1,
    },
    getRowStyle: (params) => {
        if (params.node.rowIndex % 2 === 0) {
            return {
                background: "#F5F5F5 0% 0% no-repeat padding-box",
                opacity: 0.8,
                borderRadius: "10px",
                height: "70px",
                width: "370px",
            };
        } else {
            return {
                background: "#FAFAFA 0% 0% no-repeat padding-box",
                opacity: 0.8,
                borderRadius: "10px",
                height: "70px",
                width: "370px",
            };
        }
    },
};

const CustomerByAccountModal = ({ showModel, handleClose, id, name, type }) => {
    const [loading, setLoading] = useState(true);
    const [customer, setCustomer] = useState([]);
    const getUsers = async () => {
        setLoading(true);
        try {
            let url = `/customers/by-account/${id}`;
            if (type === "vendor") {
                url = `/vendor/by-account/${id}`;
            }
            const { data, message } = await api.get(url);
            if (data) {
                setCustomer(get(data, "data", []));
                setLoading(false);
            }
            if (message) {
                setLoading(false);
                throw new Error(message);
            }
            console.info(`🙂 -> file: Accounts.js:87 -> data:`, data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getUsers();
        return () => {
            setCustomer([]);
        };
    }, []);

    const gridRef = useRef();

    const columnDefs = [
        {
            headerName: "Customer Name",
            field: type === "vendor" ? "ActualVendorName" : "ActualCustomerName",
            width: 200,
            suppressMovable: true,
            cellStyle: { paddingTop: "20px", paddingLeft: "38px" },
        },
    ];

    return (
        <Modal title={`Customer for account: ${name}`} onClose={handleClose} showModel={showModel} className={"modal_content customer-account"} id="customer-by-account-modal">
            <AgGridReact ref={gridRef} columnDefs={columnDefs} rowData={customer} gridOptions={gridOptions} suppressScrollOnNewData={true} suppressHorizontalScroll={true} headerHeight="50" suppressPaginationPanel={true} suppressDragLeaveHidesColumns={true} rowHeight="80" />
            {loading && <Loading />}
            <div className="footerButtons">
                <div className="cancelButton">
                    <button onClick={handleClose}>Close</button>
                </div>
            </div>
        </Modal>
    );
};

CustomerByAccountModal.propTypes = {
    showModel: PropTypes.bool,
    handleClose: PropTypes.func,
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string
};

export default CustomerByAccountModal;
