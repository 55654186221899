import React, { useState, useEffect } from 'react'
import { Header } from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import { Modal } from '../../components/modal/Modal'
import { Delete } from '../../components/deleteModal/Delete'
import { Button } from '../../stories/Button/Button'
import './webhook.scss'
import Edit from '../../components/editModal/Edit'
import Loading from '../../components/loader/Loading'
import ReactPaginate from 'react-paginate'
import { Auth } from 'aws-amplify'
import { Helmet } from 'react-helmet'
import api from '../../api/axiosClient'
import { get } from 'lodash'
import toast from 'react-hot-toast'

const initialValues = {
  ApiKey: '',
  Preference: '',
  EventType: '',
  Endpoint: '',
  SharedSecret: '',
}

export const Webhook = () => {
  const [gridApi, setGridApi] = useState(null)
  const [showModel, setShowModel] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showEditModel, setShowEditModel] = useState(false)
  const [editFormData, setEditFormData] = useState({
    Subscription_Preference: '',
    Endpoint: '',
    Shared_Secret: '',
  })
  const [apiKey, setApiKey] = useState()
  const [editConfirm, setEditConfirm] = useState(false)
  const [tableData, setTableData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState(initialValues)
  const [eventType, setEventType] = useState()
  const [endPoint, setEndPoint] = useState()
  const [addSuccess, setAddSuccess] = useState(false)
  const [allEvents, setAllEvents] = useState([])
  const [preferenceError, setPrefError] = useState({})
  const [eventError, setEventError] = useState({})
  const [endError, setEndError] = useState({})
  const [secretError, setSecretError] = useState({})
  const [pageCount, setPageCount] = useState()
  const [pageSummary, setPageSummary] = useState()
  const [names, setNames] = useState([])
  const [deleteError, setDeleteError] = useState(false)
  const [editPrefError, setEditPrefError] = useState({})
  const [editEndError, setEditEndError] = useState({})
  const [editSecretError, setEditSecretError] = useState({})
  const accountId = Auth.Credentials.Auth

  const columnDefs = [
    {
      headerName: 'Preference',
      field: 'Subscription_Preference',
      width: 200,
      suppressMovable: true,
      cellStyle: { paddingLeft: '35px', paddingTop: '20px' },
    },
    {
      headerName: 'Subscription Arn',
      field: 'Subscription_arn',
      suppressMovable: true,
      width: 500,
      cellStyle: { paddingLeft: '30px', paddingTop: '20px' },
    },
    {
      headerName: 'Subscribed Event',
      field: 'Event_Type',
      width: 300,
      suppressMovable: true,
      cellStyle: { paddingLeft: '30px', paddingTop: '20px' },
    },
    {
      headerName: 'End Point',
      field: 'Endpoint',
      width: 300,
      suppressMovable: true,
      cellStyle: { paddingLeft: '30px', paddingTop: '20px' },
    },
    {
      headerName: 'Shared Secret',
      field: 'Shared_Secret',
      suppressMovable: true,

      cellStyle: {
        paddingLeft: '50px',
        paddingTop: '20px',
        width: 300,
        fontWeight: 'bold',
      },
    },
    {
      headerName: 'Action',
      field: 'id',
      suppressMovable: true,

      cellRendererFramework: (params) => (
        <div className="actionIconsWeb">
          <button
            className="editWeb"
            onClick={() => {
              setShowEditModel(!showEditModel)
              setEventType(params.data.Event_Type)
              handleUpdate(params.data)
              setApiKey(params.data.ApiKey)
            }}
          >
            {/* <img id="editW" src={editLogo} alt="edit" /> */}
            <svg
              id="edit"
              xmlns="http://www.w3.org/2000/svg"
              width="14.722"
              height="14.722"
              viewBox="0 0 14.722 14.722"
            >
              <path
                className="a"
                d="M13.293,18.318H4.025A2.027,2.027,0,0,1,2,16.293V7.025A2.027,2.027,0,0,1,4.025,5H8.659a.7.7,0,1,1,0,1.4H4.025a.624.624,0,0,0-.623.623v9.268a.624.624,0,0,0,.623.623h9.268a.624.624,0,0,0,.623-.623V11.659a.7.7,0,1,1,1.4,0v4.634A2.027,2.027,0,0,1,13.293,18.318Z"
                transform="translate(-2 -3.596)"
              />
              <path
                className="a"
                d="M19.645,1.818a2.105,2.105,0,0,1,1.489,3.594L14.845,11.7a.7.7,0,0,1-.326.184l-2.648.662a.7.7,0,0,1-.85-.85l.662-2.648a.7.7,0,0,1,.184-.326l6.289-6.289A2.092,2.092,0,0,1,19.645,1.818Zm-5.654,8.754,6.152-6.152a.7.7,0,1,0-.995-.995L13,9.578,12.665,10.9Z"
                transform="translate(-7.028 -1.818)"
              />
            </svg>
          </button>
          <button
            className="deleteWeb"
            onClick={() => {
              setShowDelete(true)
              console.log(params.data)
              setApiKey(params.data.ApiKey)
              setEventType(params.data.Event_Type)
              setEndPoint(params.data.Endpoint)
            }}
          >
            {/* <img id="deleteW" src={deleteLogo} alt="delete" /> */}
            <svg
              id="delete"
              xmlns="http://www.w3.org/2000/svg"
              width="10.753"
              height="14.961"
              viewBox="0 0 10.753 14.961"
            >
              <path
                className="a"
                d="M8.268,17.8A1.608,1.608,0,0,0,9.8,19.461h6.145A1.608,1.608,0,0,0,17.485,17.8V7.825H8.268ZM18.253,5.331H15.565L14.8,4.5h-3.84l-.768.831H7.5V6.993H18.253Z"
                transform="translate(-7.5 -4.5)"
              />
            </svg>
          </button>
        </div>
      ),

      cellStyle: { paddingTop: '18px', paddingLeft: '25px' },
    },
  ]

  const gridOptions = {
    rowStyle: {
      font: 'normal normal normal 12px/21px Poppins',
      color: '#71758A',
    },
    getRowStyle: (params) => {
      if (params.node.rowIndex % 2 === 0) {
        return {
          background: '#F5F5F5 0% 0% no-repeat padding-box',
          opacity: 0.8,
          borderRadius: '10px',
          height: '70px',
        }
      } else {
        return {
          background: '#FAFAFA 0% 0% no-repeat padding-box',
          opacity: 0.8,
          borderRadius: '10px',
          height: '70px',
        }
      }
    },
    suppressRowTransform: true,
  }
  useEffect(() => {
    getNames()
  }, [])

  const getNames = () => {
    setLoading(true)
      api.get('/customers/active')
      .then(async (response) => {
        const resp = get(response, "data", {})
        setNames(resp.Customers)
        await getSubscriptions(resp.Customers[0].ApiKey)
        await getEvents(resp.Customers[0].ApiKey)
      })
      .catch((err) => {
        console.info(err)
        setLoading(true)
      })
  }
  const getSubscriptions = async (apiKey) => {
    setLoading(true)

    api
      .get(
        `/webhooks/subscription?page=1&size=10`,
        {},
        {
          'x-api-key': apiKey,
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
      )
      .then((res) => {
        setTableData(res.data.Subscription)
        setPageCount(res.data.Page.TotalPages)
        setPageSummary(1)
        setLoading(false)
      })
      .catch((err) => {
        setTableData(err.data)
        setLoading(false)
      })
  }

  const fetchSubscriptions = async (currentPage) => {
    setLoading(true)
    const res = await api.get(
      `/webhooks/subscription?page=${currentPage}&size=10`,
      {},
      {
          'x-api-key': names[0].ApiKey,
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
      },
    )
    let data = get(res, "data")
    setTableData(data.Subscription)
    setPageSummary(currentPage)
    setLoading(false)
    return data
  }

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1
    const customerServer = await fetchSubscriptions(currentPage)
    setTableData(customerServer)
  }

  const getEvents = async(apiKey) => {
    setLoading(true)
    api.get("/events", {}, {
      'x-api-key': apiKey,
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    })
    .then((response) => {
      const resp = get(response, "data", {})
        setAllEvents(resp)
        setLoading(false)
      })
      .catch((err) => {
        console.info(err)
        setLoading(false)
      })
  }
  const onGridReady = (params) => {
    setGridApi(params)
  }

  function onSubmit(e) {
    e.preventDefault()
    const isValid = formValidation()
    if (isValid) {
      setLoading(true)
      api
        .post(
          `subscriptions`,
          {
            UserId: accountId.user.username,
            Preference: formData.Preference,
            EventType: formData.EventType,
            Endpoint: formData.Endpoint,
            SharedSecret: formData.SharedSecret,
          },
          {
            'x-api-key': formData.ApiKey,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        )
        .then((res) => {
          setShowModel(false)
          getSubscriptions()
          setLoading(false)
          setFormData(initialValues)
          if (res.status === 202 || res.status === 200) {
            setAddSuccess(true)
            toast.success("Subscription added successfully!")
            return
          }
        })
        .catch((err) => {
          console.info('error', err)
          setLoading(false)
        })
    }
  }

  const formValidation = () => {
    const prefError = {}
    const eventsError = {}
    const endpointError = {}
    const secretsError = {}
    const regex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ //NOSONAR
    let isValid = true

    if (formData.Preference.trim().length < 1) {
      prefError.prefEmpty = 'Preference is required!'
      isValid = false
    }
    if (formData.EventType.trim().length < 1) {
      eventsError.eventEmpty = 'Event type is required!'
      isValid = false
    }
    if (formData.Endpoint.trim().length < 1) {
      endpointError.endEmpty = 'End Point is required!'
      isValid = false
    } else if (!regex.test(formData.Endpoint)) {
      endpointError.endFor = 'Invalid URL format'
      isValid = false
    }
    if (formData.SharedSecret.trim().length < 1) {
      secretsError.secretEmpty = 'Shared Secret is required!'
      isValid = false
    }

    setPrefError(prefError)
    setEventError(eventsError)
    setEndError(endpointError)
    setSecretError(secretsError)
    return isValid
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const onEdit = (e) => {
    e.preventDefault()
    const isValid = editValidations()
    if (isValid) {
      setLoading(true)
      setShowEditModel(false)
      api
        .put(
          `/subscriptions`,
          {
            UserId: accountId.user.username,
            Preference: editFormData.Subscription_Preference,
            EventType: eventType,
            Endpoint: editFormData.Endpoint,
            SharedSecret: editFormData.Shared_Secret,
          },
          {
            'x-api-key': apiKey,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        )
        .then((res) => {
          getSubscriptions()
          setLoading(false)
          if (res.status === 200 || res.status === 202) {
            setEditConfirm(true)
            toast.success("Subscription edited successfully!")
            return
          }

          setFormData(initialValues)
        })
        .catch((err) => {
          setLoading(false)
          console.info(err)
        })
    }
  }
  function editValidations() {
    const editPError = {}
    const editEError = {}
    const editSError = {}

    let isValid = true

    if (editFormData.Subscription_Preference.trim().length < 1) {
      editPError.pEmpty = 'Field is required!'
      isValid = false
    }

    if (editFormData.Endpoint.trim().length < 1) {
      editEError.eEmpty = 'Field is required!'
      isValid = false
    }

    if (editFormData.Shared_Secret.trim().length < 1) {
      editSError.sEmpty = 'Field is required!'
      isValid = false
    }
    setEditPrefError(editPError)
    setEditEndError(editEError)
    setEditSecretError(editSError)
    return isValid
  }

  const onDelete = () => {
    setLoading(true)
    setShowDelete(false)
    api
      .delete(`/webhooks`, 
        {
          EventType: eventType,
          Endpoint: endPoint,
          UserId: accountId.user.username,
        },
        {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      )
      .then((resp) => {
        getSubscriptions()
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setDeleteError(true)
        toast.error("Cannot delete this Subscription.")
      })
  }

  const handleUpdate = (prevData) => {
    // console.log(names)
    setEditFormData(prevData)
    setShowEditModel(true)
  }
  const handleClose = () => {
    setShowModel(false)
    setFormData(initialValues)
    setPrefError('')
    setEventError('')
    setEndError('')
    setSecretError('')
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Webhook Subscription</title>
      </Helmet>

      {loading && <Loading data-testid="load" />}
      <Header />
      <Sidebar />
      <div className="webhookPageContent">
        <div className="webhookCard">
          <div className="webhookCardBody">
            <div className="webhookLabelText">
              <h3>Subscription </h3>
            </div>
            <div className="addSubscription">
              <Button
                label={'Add New Subscription'}
                onClick={() => setShowModel(true)}
              />
              <Modal
                title="Add new Subscription"
                onClose={() => setShowModel(false)}
                showModel={showModel}
                onSubmit={onSubmit}
                className={'modal_contentWeb'}
              >
                <form noValidate>
                  <div className="form_group">
                    <select
                      defaultValue={formData.ApiKey}
                      className="select_controlWeb"
                      name="ApiKey"
                      id="apiKey"
                      onChange={handleChange}
                    >
                      <option value={formData.ApiKey} disabled>
                        Customer Name
                      </option>
                      {names?.map((item, index) => {
                        return (
                          <option key={JSON.stringify(item)} value={item.ApiKey}>
                            {item.CustomerName}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="form_group">
                    <select
                      defaultValue={formData.Preference}
                      className="select_controlWeb"
                      name="Preference"
                      id="preference"
                      onChange={handleChange}
                    >
                      <option value={formData.Preference} disabled>
                        Preference
                      </option>
                      <option value="fullPayload">Full Payload</option>
                      <option value="change">Change</option>
                    </select>
                    {Object.keys(preferenceError).map((key, index) => {
                      return (
                        <div key={key} className="errorMessage">
                          {preferenceError[key]}
                          {''}
                        </div>
                      )
                    })}
                  </div>

                  <div className="form_group">
                    <select
                      defaultValue={formData.EventType}
                      className="select_controlWeb"
                      name="EventType"
                      id="event"
                      onChange={handleChange}
                    >
                      <option value={formData.EventType} disabled>
                        Event Type
                      </option>

                      {allEvents.map((item, index) => {
                        return (
                          <option key={item.Event_Type} value={item.Event_Type}>
                            {item.Event_Type}
                          </option>
                        )
                      })}
                    </select>
                    {Object.keys(eventError).map((key, index) => {
                      return (
                        <div key={key} className="errorMessage">
                          {eventError[key]}
                          {''}
                        </div>
                      )
                    })}
                  </div>
                  <div className="form_group">
                    <input
                      type="text"
                      className="form_control"
                      id="Endpoint"
                      placeholder="End Point"
                      name="Endpoint"
                      value={formData.Endpoint}
                      onChange={handleChange}
                    />
                    {Object.keys(endError).map((key, index) => {
                      return (
                        <div key={key} className="errorMessage">
                          {endError[key]}
                          {''}
                        </div>
                      )
                    })}
                  </div>
                  <div className="form_group">
                    <input
                      type="number"
                      className="form_control"
                      id="secret"
                      placeholder="Shared Secret"
                      name="SharedSecret"
                      value={formData.SharedSecret}
                      onChange={handleChange}
                    />
                    {Object.keys(secretError).map((key, index) => {
                      return (
                        <div key={key} className="errorMessage">
                          {secretError[key]}
                          {''}
                        </div>
                      )
                    })}
                  </div>

                  <div className="footerButtons">
                    <div className="save">
                      <button id="test" type="submit" onClick={onSubmit}>
                        Save
                      </button>
                    </div>
                    <div className="cancelButton">
                      <button onClick={handleClose}>Close</button>
                    </div>
                  </div>
                </form>
              </Modal>
            </div>

            <Delete
              onClose={() => setShowDelete(false)}
              showDelete={showDelete}
              title={
                'Are you sure you want to delete this Customer Subscription?'
              }
              onDelete={onDelete}
            />
            <Edit
              title="Edit a Subscription"
              onClose={() => setShowEditModel(false)}
              showEditModel={showEditModel}
              onEdit={onEdit}
            >
              <form onSubmit={onEdit} data-testid="form">
                <div className="form_group">
                  <label className="labelEdit" htmlFor='preference'>Preference</label>
                  <select
                    defaultValue={editFormData.Subscription_Preference}
                    className="select_controlWebEdit"
                    name="Preference"
                    id="preference"
                    onChange={(e) =>
                      setEditFormData((prev) => ({
                        ...prev,
                        Subscription_Preference: e.target.value,
                      }))
                    }
                  >
                    <option value={formData.Preference} disabled>
                      Preference
                    </option>
                    <option value="fullPayload">Full Payload</option>
                    <option value="change">Change</option>
                  </select>
                  {Object.keys(editPrefError).map((key, index) => {
                    return (
                      <div key={key} className="errorMessage">
                        {editPrefError[key]}
                        {''}
                      </div>
                    )
                  })}
                </div>

                <div className="form_groupE">
                  <label className="labelEdit" htmlFor='Endpoint'>End Point</label>

                  <input
                    className="form_controlE"
                    type="text"
                    id="Endpoint"
                    name="Endpoint"
                    value={editFormData.Endpoint}
                    onChange={(e) =>
                      setEditFormData((prev) => ({
                        ...prev,
                        Endpoint: e.target.value,
                      }))
                    }
                  />
                  {Object.keys(editEndError).map((key, index) => {
                    return (
                      <div key={key} className="errorMessage">
                        {editEndError[key]}
                        {''}
                      </div>
                    )
                  })}
                </div>

                <div className="form_groupE">
                  <label className="labelEdit" htmlFor='Endpoint'>Shared Secret </label>

                  <input
                    className="form_controlE"
                    type="text"
                    id="Endpoint"
                    name="Shared_Secret"
                    value={editFormData.Shared_Secret}
                    onChange={(e) =>
                      setEditFormData((prev) => ({
                        ...prev,
                        Shared_Secret: e.target.value,
                      }))
                    }
                  />
                  {Object.keys(editSecretError).map((key, index) => {
                    return (
                      <div key={key} className="errorMessage">
                        {editSecretError[key]}
                        {''}
                      </div>
                    )
                  })}
                </div>

                <div className="footerButtonsE">
                  <div className="save">
                    <button type="submit">Save</button>
                  </div>
                  <div className="cancelButton">
                    <button onClick={() => setShowEditModel(false)}>
                      Close
                    </button>
                  </div>
                </div>
              </form>
            </Edit>
            <div
              className="ag-theme-balham"
              style={{
                width: '95%',
                height: '950px',
              }}
            >
              <AgGridReact
                columnDefs={columnDefs}
                suppressHorizontalScroll={true}
                rowData={tableData}
                gridOptions={gridOptions}
                pagination={true}
                paginationPageSize={10}
                suppressRowTransform={true}
                suppressPaginationPanel={true}
                paginationAutoPageSize={true}
                headerHeight="50"
                rowHeight="80"
                onGridReady={onGridReady}
                overlayLoadingTemplate={`<span className="ag-overlay-loading-center">
                </span>`}
              />
              {tableData && (
                <div className="mainPa">
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    onPageChange={handlePageClick}
                    initialPage={0}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    containerClassName={'pagination justify-content-end'}
                    pageCount={pageCount}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  ></ReactPaginate>
                </div>
              )}
              <div className="summaryPanel">
                <span className="summary">
                  {pageSummary} of {pageCount}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Webhook
