import { get } from "lodash";
import SimpleDropdown from "../dropdown/Dropdown";
import { Modal } from "../modal/Modal";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Loading from "../loader/Loading";
import api from "../../api/axiosClient";

const AssociateAccountModal = ({ showModel, onSubmit, formData, setFormData, associateAccountError, handleClose, title, type }) => {
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const getUsers = async () => {
        setLoading(true);
        try {
            let url;
            if (type === "customer") {
                url = "/accounts/get";
            }
            if (type === "vendor") {
                url = "/vendoraccount/get";
            }
            const { data, message } = await api.get(url);
            if (data) {
                setAccounts(get(data, "data"));
                setLoading(false);
            }
            if (message) {
                setLoading(false);
                throw new Error(message);
            }
            console.info(`🙂 -> file: Accounts.js:87 -> data:`, data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getUsers();
        return () => {
            setAccounts([]);
        };
    }, []);

    return (
        <Modal title={title} onClose={handleClose} showModel={showModel} className={"modal_content promote_modal"}>
            <form onSubmit={onSubmit}>
                <div className="form_group">
                    <input type="text" className="form_control" id="name" name="Name" placeholder="Name" value={get(formData, "customerName", "")} disabled />
                </div>
                <div className="form_group">
                    <SimpleDropdown
                        title="Account Name"
                        displayEmpty
                        value={get(formData, "accountName", "")}
                        onChange={(e) =>
                            setFormData((prev) => ({
                                ...prev,
                                accountName: e.target.value,
                            }))
                        }
                        data={accounts.map((account) => ({ value: get(account, type === "customer" ? "ActualAccountName" : "AccountName"), label: get(account, type === "customer" ? "ActualAccountName" : "AccountName") }))}
                    />
                    {Object.keys(associateAccountError).map((key) => {
                        return (
                            <div key={key} className="errorMessage">
                                {associateAccountError[key]}
                            </div>
                        );
                    })}
                </div>
                <div className="footerButtons">
                    <div className="save">
                        <button type="submit">Save</button>
                    </div>
                    <div className="cancelButton">
                        <button onClick={handleClose}>Close</button>
                    </div>
                </div>
            </form>
            {loading && <Loading />}
        </Modal>
    );
};

AssociateAccountModal.propTypes = {
    showModel: PropTypes.bool,
    onSubmit: PropTypes.func,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    associateAccountError: PropTypes.object,
    handleClose: PropTypes.func,
    title: PropTypes.string,
    type: PropTypes.string,
};

export default AssociateAccountModal;
