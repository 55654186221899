import React from 'react'
import { SidebarData } from './SidebarData'
import { Link } from 'react-router-dom'
import './sidebar.scss'
import logoImg from '../../assets/images/omni.png'
import logoL from '../../assets/images/omni_title.png'
import { Toggle } from '../toggle/Toggle'

export const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false)

  function handlePageSelect(e, index) {
    if (SidebarData[index].cName.split(' ').includes('disabled')) {
      e.preventDefault()
    }
    SidebarData.map((lnk, idx) => {
      if (index === idx) {
        lnk.isActive = true
        return lnk
      } else {
        lnk.isActive = false
        return lnk
      }
    })
  }

  return (
    <>
      <nav className={sidebarOpen ? 'nav-menu-active' : 'nav-menu'}>
        {!sidebarOpen && (
          <div className="logo">
            <img src={logoImg} alt="logoImage" />
          </div>
        )}
        {sidebarOpen && (
          <div className="logoL">
            <img src={logoL} alt="logoImage" />
          </div>
        )}

        <div className="toggle">
          <Toggle
            isToggled={sidebarOpen}
            onToggle={() => setSidebarOpen(!sidebarOpen)}
          />
        </div>

        <div className="nav-menu-items">
          <ul>
            {SidebarData.map((item, index) => {
              return (
                <React.Fragment key={`sidebar-links-${index}`}>
                  <li key={index} className={item.cName}>
                    <Link
                      to={item.path}
                      onClick={(e) => handlePageSelect(e, index)}
                      style={{ color: item.isActive ? '#fff' : 'inheret' }}
                      className={item.isActive ? 'activePage' : 'inheret'}
                    >
                      <div
                        className={item.isActive ? 'activeLink' : 'inheret'}
                      ></div>
                      <span id="iconN">{item.icon} </span>
                      <span id="title">{item.title}</span>

                      {item.cName === 'nav-text inactive' ? (
                        <span className="tooltiptext">Coming Soon</span>
                      ) : null}
                    </Link>
                  </li>
                </React.Fragment>
              )
            })}
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Sidebar
