import { get } from "lodash";
import PropTypes from "prop-types";
import SimpleDropdown from "../dropdown/Dropdown";
import { Modal } from "../modal/Modal";

const PromoteModal = ({ showModel, onSubmit, formData, setFormData, apiKeyPreferenceError, handleClose, title, dropdownData }) => (
    <Modal title={title} onClose={handleClose} showModel={showModel} className={"modal_content promote_modal"}>
        <form onSubmit={onSubmit}>
            <div className="form_group">
                <input type="text" className="form_control" id="name" name="Name" placeholder="Name" value={get(formData, "customerName", "")} disabled />
            </div>
            <div className="form_group">
                <SimpleDropdown
                    title="API Key Preference"
                    displayEmpty
                    value={get(formData, "apiKeyPreference", "")}
                    onChange={(e) =>
                        setFormData((prev) => ({
                            ...prev,
                            apiKeyPreference: e.target.value,
                        }))
                    }
                    data={dropdownData}
                />
                {Object.keys(apiKeyPreferenceError).map((key) => {
                    return (
                        <div key={key} className="errorMessage">
                            {apiKeyPreferenceError[key]}{" "}
                        </div>
                    );
                })}
            </div>
            <div className="footerButtons">
                <div className="save">
                    <button type="submit">Save</button>
                </div>
                <div className="cancelButton">
                    <button onClick={handleClose}>Close</button>
                </div>
            </div>
        </form>
    </Modal>
);

PromoteModal.propTypes = {
    showModel: PropTypes.bool,
    onSubmit: PropTypes.func,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    apiKeyPreferenceError: PropTypes.object,
    handleClose: PropTypes.func,
    title: PropTypes.string,
    dropdownData: PropTypes.arrayOf(PropTypes.object),
};

export default PromoteModal;
