import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../stories/Button/Button'
import './apiGenerate.scss'

export function ApiGenerate({showModal, title, onClose, onGenerate}) {
  if (!showModal) {
    return null
  }

  return (
    <div className="apiGenerateModal">
      <div className="apiModal_content">
        <div className="apiModal_title">
          <h4>{title}</h4>
        </div>
        <div className="apiModal_buttons">
          <div className="noGenerate">
            <Button
              onClick={onClose}
              label={'No'}
              primary={true}
              size={'large'}
            ></Button>
          </div>
          <div className="yesGenerate">
            <Button
              label={'Yes'}
              className="yesG"
              size={'large'}
              onClick={onGenerate}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ApiGenerate

ApiGenerate.propTypes = {
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onGenerate: PropTypes.func.isRequired,
};
