import React from 'react'
import './modal.scss'

export const Modal = (props) => {
  if (!props.showModel) {
    return null
  }
  return (
    <div className="Modal">
      <div className={props.className}>
        <div className="modal_header">
          <h3>{props.title}</h3>
          {props.image}
        </div>
        <div className="modal_body">{props.children}</div>
      </div>
    </div>
  )
}
