import React from 'react'
import PropTypes from 'prop-types'
import './button.scss'

export const Button = ({
  primary,
  backgroundColor,
  size,
  label,
  icon,
  className,
  isActive,
  ...props
}) => {
  const mode = primary
    ? 'storybook-button--primary'
    : 'storybook-button--secondary'
  return (
    <button
      type="button"
      className={[
        'storybook-button',
        `storybook-button--${size}`,
        mode,
        className,
      ].join(' ')}
      style={backgroundColor && { backgroundColor }}
      {...props}
    >
      {icon && <img src={icon} alt="" />}
      {label}
    </button>
  )
}

Button.propTypes = {
  primary: PropTypes.bool,
  icon: PropTypes.string,
  backgroundColor: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
}

Button.defaultProps = {
  backgroundColor: null,
  primary: false,
  size: 'medium',
  onClick: undefined,
  isActive: false,
}
