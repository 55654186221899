import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react";
import { debounce, get } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import api from "../../api/axiosClient";
import searchIcon from "../../assets/icons/Icon feather-search.svg";
import AddAccountModal from "../../components/addAccountModal/AddAccountModal";
import Delete from "../../components/deleteModal/Delete";
import { Header } from "../../components/header/Header";
import Loading from "../../components/loader/Loading";
import Sidebar from "../../components/sidebar/Sidebar";
import { Button } from "../../stories/Button/Button";
import "./ag-grid-overrides.scss";
import "./vendor-accounts.scss";
import CustomerByAccountModal from "../../components/customerByAccountModal/CustomerByAccountModal";

const initialValues = {
    name: "",
    apiKeyPreference: "",
};

const gridOptions = {
    rowStyle: {
        font: "normal normal normal 14px/21px Poppins",
        color: "#71758A",
        opacity: 1,
    },
    getRowStyle: (params) => {
        if (params.node.rowIndex % 2 === 0) {
            return {
                background: "#F5F5F5 0% 0% no-repeat padding-box",
                opacity: 0.8,
                borderRadius: "10px",
                height: "70px",
            };
        } else {
            return {
                background: "#FAFAFA 0% 0% no-repeat padding-box",
                opacity: 0.8,
                borderRadius: "10px",
                height: "70px",
            };
        }
    },
};

const size = 10;

const VendorAccounts = () => {
    const [tableData, setTableData] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const [formData, setFormData] = useState(initialValues);
    const [searchText, setSearchText] = useState("");
    const [apiKeyPreferenceError, setApiKeyPreferenceError] = useState({});
    const [nameErrors, setNameErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [showDelete, setShowDelete] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [pageSummary, setPageSummary] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [showCustomerModal, setShowCustomerModal] = useState(false);

    const getUsers = useCallback(async (page, keyword) => {
        setLoading(true);
        try {
            const { data, message } = await api.get(`/vendoraccount/get?page=${page}&size=${size}&keyword=${keyword}`);
            console.info(`🙂 -> data, message:`, data, message);
            if (data) {
                if (page <= 1) {
                    setTableData([]);
                }
                setTableData(get(data, "data", []));
                setPageCount(Math.ceil(get(data, "count", 0) / size));
                setLoading(false);
            }
            if (message) {
                setLoading(false);
                throw new Error(message);
            }
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        document.getElementById("account").scrollIntoView();
        getUsers(pageSummary, searchText);
    }, [getUsers, pageSummary]);

    const search = debounce((text) => {
        setSearchText(text);
    }, 100);

    async function handleUpdateSubmit(updateDetail) {
        setLoading(true);
        try {
            const { status, data, message } = await api.put("vendoraccount/update", updateDetail);
            console.info(`🙂 -> file: VendorAccounts.js:92 -> status, data, message:`, status, data, message);
            if (data) {
                handleClose();
                getUsers(pageSummary, searchText);
                setLoading(false);
                toast.success(get(data, "message", "Account updated."));
            }
            if (message) {
                setLoading(false);
                throw new Error(message);
            }
        } catch (err) {
            console.error(err);
            setLoading(false);
            toast.error(err.message);
        }
    }

    async function onSubmit(e) {
        e.preventDefault();
        const isValid = formValidation();
        if (isValid) {
            setLoading(true);
            try {
                let accountDetail = {
                    name: formData.name,
                    preference: formData.apiKeyPreference,
                };
                if (isEdit) {
                    const updateDetail = { KeyPreference: formData.apiKeyPreference, AccountNumber: formData.accountId };
                    handleUpdateSubmit(updateDetail);
                    return;
                }
                const { status, data, message } = await api.post("/vendoraccount/add", accountDetail);
                console.info(`🙂 -> file: VendorAccounts.js:102 -> status, data, message:`, status, data, message);
                if (data) {
                    handleClose();
                    getUsers(pageSummary, searchText);
                    setLoading(false);
                    toast.success(get(data, "message", "Account created."));
                }
                if (message) {
                    setLoading(false);
                    throw new Error(message);
                }
            } catch (err) {
                console.error(err);
                setLoading(false);
                toast.error(err.message);
            }
        }
    }

    const handleClose = () => {
        setShowModel(false);
        setFormData(initialValues);
        setNameErrors({});
        setApiKeyPreferenceError({});
        if (isEdit) {
            setIsEdit(false);
        }
    };

    const columnDefs = [
        {
            headerName: "Account Name",
            field: "AccountName",
            width: 200,
            suppressMovable: true,
            cellStyle: { paddingTop: "20px", paddingLeft: "38px" },
            cellRendererFramework: (params) => { //NOSONAR
                return (
                    <span //NOSONAR
                        onClick={() => {
                            setCurrentRow(params.data);
                            setShowCustomerModal(true);
                        }}
                    >
                        {get(params, "value")}
                    </span>
                );
            },
        },
        {
            headerName: "Status",
            field: "CustomerStatus",
            // filter: 'DeclaredType',
            width: 220,
            suppressMovable: true,
            cellStyle: { paddingTop: "20px", paddingLeft: "30px" },
            cellClass: function (params) {
                return get(params, "data.VendorStatus", get(params, "data.CustomerStatus")) === "Active" ? "active" : "inactive";
            },
            cellRendererFramework: function (params) {
                return get(params, "data.VendorStatus", get(params, "data.CustomerStatus"));
            },
        },
        {
            headerName: "Preference",
            field: "KeyPreference",
            width: 220,
            suppressMovable: true,
            cellStyle: { paddingTop: "20px", paddingLeft: "30px" },
            cellRendererFramework: (params) => {
                const preferenceMapping = { byVendor: "By Vendor", byVendorAccount: "By VendorAccount" };
                return get(preferenceMapping, get(params, "data.KeyPreference"), get(params, "data.KeyPreference"));
            },
        },
        {
            headerName: "Action",
            field: "id",
            width: 120,
            suppressMovable: true,
            cellRendererFramework: (params) => ( //NOSONAR
                <div className="actionIcons">
                    <button
                        className="editButton"
                        onClick={() => {
                            setIsEdit(true);
                            setShowModel(true);
                            setFormData({ name: get(params, "data.AccountName"), apiKeyPreference: get(params, "data.KeyPreference"), accountId: get(params, "data.AccountId") });
                        }}
                    >
                        <svg id="edit" xmlns="http://www.w3.org/2000/svg" width="14.722" height="14.722" viewBox="0 0 14.722 14.722">
                            <path className="a" d="M13.293,18.318H4.025A2.027,2.027,0,0,1,2,16.293V7.025A2.027,2.027,0,0,1,4.025,5H8.659a.7.7,0,1,1,0,1.4H4.025a.624.624,0,0,0-.623.623v9.268a.624.624,0,0,0,.623.623h9.268a.624.624,0,0,0,.623-.623V11.659a.7.7,0,1,1,1.4,0v4.634A2.027,2.027,0,0,1,13.293,18.318Z" transform="translate(-2 -3.596)" />
                            <path className="a" d="M19.645,1.818a2.105,2.105,0,0,1,1.489,3.594L14.845,11.7a.7.7,0,0,1-.326.184l-2.648.662a.7.7,0,0,1-.85-.85l.662-2.648a.7.7,0,0,1,.184-.326l6.289-6.289A2.092,2.092,0,0,1,19.645,1.818Zm-5.654,8.754,6.152-6.152a.7.7,0,1,0-.995-.995L13,9.578,12.665,10.9Z" transform="translate(-7.028 -1.818)" />
                        </svg>
                    </button>
                    <button
                        className="deleteButton"
                        onClick={() => {
                            setShowDelete(true);
                            setCurrentRow(params.data);
                        }}
                    >
                        <svg id="delete" xmlns="http://www.w3.org/2000/svg" width="10.753" height="14.961" viewBox="0 0 10.753 14.961">
                            <path className="a" d="M8.268,17.8A1.608,1.608,0,0,0,9.8,19.461h6.145A1.608,1.608,0,0,0,17.485,17.8V7.825H8.268ZM18.253,5.331H15.565L14.8,4.5h-3.84l-.768.831H7.5V6.993H18.253Z" transform="translate(-7.5 -4.5)" />
                        </svg>
                    </button>
                </div>
            ),
            cellStyle: { paddingTop: "18px", paddingLeft: "20px" },
        },
    ];

    function formValidation() {
        const nameError = {};
        const apiKeyPreferenceError = {};
        const regex1 = /^[a-zA-Z0-9._-]+$/;
        let isValid = true;
        if (formData.name.trim().length < 1) {
            nameError.nameEmpty = "Name is required!";
            isValid = false;
        } else if (!regex1.test(formData.name)) {
            nameError.nameSpe = "No special characters allowed";
            isValid = false;
        }
        if (formData.apiKeyPreference.trim().length < 1) {
            apiKeyPreferenceError.apiKeyPreferenceEmpty = "Api key preference is required!";
            isValid = false;
        } else if (!regex1.test(formData.apiKeyPreference)) {
            apiKeyPreferenceError.apiKeyPreferenceSpe = "No special characters allowed";
            isValid = false;
        }

        setNameErrors(nameError);
        setApiKeyPreferenceError(apiKeyPreferenceError);

        return isValid;
    }

    const handleDelete = async () => {
        setLoading(true);
        try {
            const deleteParam = { AccountName: get(currentRow, "AccountName") };
            const { data, message } = await api.delete("/vendoraccount/del", deleteParam);
            console.info(`🙂 -> file: VendorAccounts.js:233 -> data, message:`, data, message);
            if (data) {
                setShowDelete(false);
                getUsers(pageSummary, searchText);
                setLoading(false);
                toast.success(get(data, "message", "Account deleted."));
            }
            if (message) {
                setLoading(false);
                throw new Error(message);
            }
        } catch (err) {
            console.error(err);
            setLoading(false);
            toast.error(err.message);
        }
    };

    const gridRef = useRef();

    const handlePageClick = async (data) => {
        let currentPage = data.selected + 1;
        setPageSummary(currentPage);
    };

    const searchFilter = async () => {
        setPageSummary(1);
        getUsers(1, searchText);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>VendorAccounts</title>
            </Helmet>
            <Header />
            <Sidebar />
            <div className="account-body" id="account">
                <div className="cardCustomers">
                    <div className="customers_card_body">
                        <div className="customers_label_text">
                            <h3>VendorAccounts</h3>
                        </div>
                        <div className="searchFilter">
                            <img src={searchIcon} alt="search" className="sIcon" />
                            <input type="text" className="search_bar" placeholder="Find Account" onChange={(e) => search(e.target.value)} onKeyDown={(e) => get(e, "key") === "Enter" && searchFilter()}></input>
                            <input type="submit" onClick={searchFilter}></input>
                        </div>
                        <div className="addCustomer">
                            <Button label={"Add Vendor Account"} onClick={() => setShowModel(true)}></Button>
                            <AddAccountModal
                                type={isEdit ? "EDIT" : "ADD"}
                                formData={formData}
                                handleClose={handleClose}
                                nameErrors={nameErrors}
                                onSubmit={onSubmit}
                                setFormData={setFormData}
                                setShowModel={setShowModel}
                                showModel={showModel}
                                apiKeyPreferenceError={apiKeyPreferenceError}
                                title="Add new vendor account"
                                dropdownData={[
                                    { value: "byVendor", label: "By Vendor" },
                                    { value: "byVendorAccount", label: "By VendorAccount" },
                                ]}
                            />
                        </div>
                        <div
                            className="ag-theme-balham"
                            style={{
                                width: "90%",
                                height: 950,
                                marginLeft: 12,
                            }}
                        >
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={columnDefs}
                                rowData={tableData}
                                gridOptions={gridOptions}
                                pagination={true}
                                paginationPageSize={size}
                                paginationAutoPageSize={true}
                                suppressScrollOnNewData={true}
                                suppressHorizontalScroll={true}
                                rowSelection="single"
                                headerHeight="50"
                                suppressPaginationPanel={true}
                                suppressDragLeaveHidesColumns={true}
                                rowHeight="80"
                                overlayLoadingTemplate={`<span className="ag-overlay-loading-center"></span>`}
                            />
                            {tableData && (
                                <div className="mainPa">
                                    <ReactPaginate previousLabel={"<"} nextLabel={">"} onPageChange={handlePageClick} initialPage={0} marginPagesDisplayed={1} pageRangeDisplayed={1} containerClassName={"pagination justify-content-end"} disabledClassName={"disabledPagination"} pageCount={pageCount} pageClassName={"page-item"} pageLinkClassName={"page-link"} previousClassName={"page-item"} previousLinkClassName={"page-link"} nextClassName={"page-item"} nextLinkClassName={"page-link"} activeClassName={"active"} />
                                </div>
                            )}

                            <div className="summaryPanel">
                                <span className="summary">
                                    {pageSummary} of {pageCount}
                                </span>
                            </div>
                        </div>
                        <Delete onClose={() => setShowDelete(false)} showDelete={showDelete} title={"Are you sure you want to delete this account?"} onDelete={() => handleDelete()} />
                    </div>
                </div>
            </div>
            {showCustomerModal && <CustomerByAccountModal showModel account={currentRow} handleClose={() => setShowCustomerModal(false)} id={get(currentRow, "AccountId")} name={get(currentRow, "AccountName")} type={"vendor"}/>}
            {loading && <Loading />}
        </>
    );
};

export default VendorAccounts;
